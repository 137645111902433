import React from 'react'
import '../styles/institucional.scss'
import { Helmet } from 'react-helmet'
import Swiper from 'react-id-swiper'
import Layout from '../../components/Layout/index'
import { graphql, Link } from 'gatsby'
// import Img from 'gatsby-image'

// Images
import firstShop from '../../images/institucional/1979.png'
import diaBrasil from '../../images/institucional/2001.png'
import atualmente from '../../images/institucional/2020.jpeg'

// Icons
import checkIcon from '../../images/institucional/check.svg'
import preco from '../../images/institucional/preco.svg'
import marca from '../../images/institucional/marca-propria.svg'
import frescor from '../../images/institucional/frescor.svg'
import proximidade from '../../images/institucional/proximidade.svg'
import criacaoMarca from '../../images/institucional/criacao-marca-corporativa.svg'
import bolsaValores from '../../images/institucional/2011.svg'
import logoDia from '../../images/institucional/logo-dia.svg'
// import { argsToArgsConfig } from 'graphql/type/definition'

// Dark Mode
import precoDM from '../../images/institucional/preco-light.svg'
import marcaDM from '../../images/institucional/marca-propria-light.svg'
import frescorDM from '../../images/institucional/frescor-light.svg'
import proximidadeDM from '../../images/institucional/proximidade-light.svg'

const swiperParamsPrincipios = {
  slidesPerView: 1.3,
  spaceBetween: 20,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  }
}

const params = {
  slidesPerView: 5,
  spaceBetween: 1,
  freeMode: true,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  }
}

const Timeline = () => {
  return (
    <Swiper {...params}>
      <div>
        <div className="card-timeline bottom">
          <div className="timestamp"><span>1979</span></div>
          <div className="content content-top">
            <p className='font-size-1'><b>A primeira loja DIA</b> é inaugurada em Madrid, na Espanha.</p>
            <img className="img-border" alt="Fachada da primeira loja do Dia inaugurada em Madrid, na Espanha" width={230} height={150} src={firstShop}/>
          </div>
        </div>
      </div>

      <div>
        <div className="card-timeline ">
          <div className="top">
            <div className="content content-bottom">
              <img src={criacaoMarca} alt="Logo com panos e uma garrafa"></img>
              <p className='font-size-1'>Criação da marca corporativa da empresa, com o <b>primeiro produto da marca DIA</b> chegando paralelamente às prateleiras: um amaciante.</p>
            </div>
            <div className="timestamp"><span>1984</span></div>
          </div>
        </div>
      </div>

      <div>
        <div className="card-timeline bottom">
          <div className="timestamp"><span>1993</span></div>
          <div className="content content-top">
            <p className='font-size-1'>DIA expande sua atuação internacional, chegando em <b>Portugal</b>.</p>
          </div>
        </div>
      </div>

      <div>
        <div className="card-timeline ">
          <div className="top">
            <div className="content content-bottom">
              <p className='font-size-1'>DIA inaugura sua primeira loja na <b>Argentina</b>.</p>
            </div>
            <div className="timestamp"><span>1997</span></div>
          </div>
        </div>
      </div>

      <div>
        <div className="card-timeline bottom">
          <div className="timestamp"><span>2001</span></div>
          <div className="content content-top">
            <img className="img-border" alt="Caixas do Supermercado DIA" src={diaBrasil} width={230} height={150} />
            <p className='font-size-1'><b>DIA chega no Brasil</b>. A rede, que atua em mais 3 países (Argentina, Espanha e Portugal), conta com cerca de 40 mil colaboradores.</p>
          </div>
        </div>
      </div>

      <div>
        <div className="card-timeline">
          <div className="top">
            <div className="content content-bottom">
              <img src={bolsaValores} alt="Gráfico crescente com cifrão no final"></img>
              <p className='font-size-1'>Mais uma boa notícia: o DIA entra na <b>bolsa de valores</b> de Madrid.</p>
            </div>
            <div className="timestamp"><span>2011</span></div>
          </div>
        </div>
      </div>

      <div>
        <div className="card-timeline bottom">
          <div className="timestamp"><span>2019</span></div>
          <div className="content content-top">
            <img src={logoDia} alt="Logo do Supermercado Dia"></img>
            <p className='font-size-1'>Em Maio, a <b>LetterOne</b> assume controle majoritário do Grupo DIA – cerca 74% das ações da companhia – e dá início a uma fase de transformação global. Mikhail Fridman, co-fundador do grupo L1, atua como principal investidor.</p>
          </div>
        </div>
      </div>

      <div>
        <div className="card-timeline">
          <div className="top">
            <div className="content content-bottom">
              <p className='font-size-1'>Há um Novo Dia - Definição do nosso propósito: "Estar cada dia mais perto para oferecer grande qualidade ao alcance de todos". Essa é a força motriz da nossa estratégia para construir um negócio responsável e sustentável, capaz de impactar positivamente as comunidades nas quais estamos inseridos.</p>
            </div>
            <div className="timestamp"><span>2022</span></div>
          </div>
        </div>
      </div>

      <div>
        <div className="card-timeline bottom">
          <div className="timestamp"><span>2023</span></div>
          <div className="content content-top">
            <p className='font-size-1'>Renovação dos Valores Dia: Somos uma empresa que avança guiada pelo seu objetivo e orientada pelos seus valores. A aprendizagem é o nosso motor de crescimento e fomentamos a colaboração por meio de relações autênticas e de confiança; estamos empenhados nos resultados, e a simplicidade, somada à geração de valor, nos leva a conquistar todos os dias a nossa paixão: o cliente. Este é o nosso Novo Dia.</p>
          </div>
        </div>
      </div>

      <div>
        <div className="card-timeline">
          <div className="top">
            <div className="content content-bottom">
              <p className='font-size-1'>Início de um processo de reestruturação no Dia Brasil com o objetivo de concentrar a nossa operação no Estado de São Paulo, onde operamos 244 lojas.</p>
              <br />
              <p className='font-size-1'>Fim do Vínculo com o Grupo Dia.</p>
            </div>
            <div className="timestamp"><span>2024</span></div>
          </div>
        </div>
      </div>
    </Swiper>
  )
}

const paramsTimelineMobile = {
  slidesPerView: 1.3,
  spaceBetween: 20,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  },
  breakpoints: {
    550: {
      slidesPerView: 1,
      spaceBetween: 1
    },
    700: {
      slidesPerView: 2.5,
      spaceBetween: 1
    },
    900: {
      slidesPerView: 3,
      spaceBetween: 1
    },
    1000: {
      slidesPerView: 3,
      spaceBetween: 1
    },
    1100: {
      slidesPerView: 5,
      spaceBetween: 1
    },
    1200: {
      slidesPerView: 5,
      spaceBetween: 1
    }
  }
}

const TimelineMobile = () => {
  return (
    <Swiper {...paramsTimelineMobile}>

      <div>
        <div className="card-timeline bottom">
          <div className="timestamp"><span className='font-size-15'>1979</span></div>
          <div className="content content-top">
            <p className='font-size-1'><b>A primeira loja DIA</b> é inaugurada em Madrid, na Espanha.</p>
            <img className="img-border" src={firstShop} alt="Fachada da primeira loja do Dia inaugurada em Madrid, na Espanha"/>
          </div>
        </div>
      </div>

      <div>
        <div className="card-timeline bottom">
          <div className="timestamp"><span className='font-size-15'>1984</span></div>
          <div className="content content-top">
            <img src={criacaoMarca} alt="Logo com panos e uma garrafa"></img>
            <p className='font-size-1'>Criação da marca corporativa da empresa, com o <b>primeiro produto da marca DIA</b> chegando paralelamente às prateleiras: um amaciante.</p>
          </div>
        </div>
      </div>

      <div>
        <div className="card-timeline bottom">
          <div className="timestamp"><span className='font-size-15'>1993</span></div>
          <div className="content content-top">
            <p className='font-size-1'>DIA expande sua atuação internacional, chegando em <b>Portugal</b>.</p>
          </div>
        </div>
      </div>

      <div>
        <div className="card-timeline bottom">
          <div className="timestamp"><span className='font-size-15'>1997</span></div>
          <div className="content content-top">
            <p className='font-size-1'>DIA inaugura sua primeira loja na <b>Argentina</b>.</p>
          </div>
        </div>
      </div>

      <div>
        <div className="card-timeline bottom">
          <div className="timestamp"><span className='font-size-15'>2001</span></div>
          <div className="content content-top">
            <img className="img-border" src={diaBrasil} alt="Caixas do Supermercado DIA"/>
            <p className='font-size-1'><b>DIA chega no Brasil</b>. A rede, que atua em mais 3 países (Argentina, Espanha e Portugal), conta com cerca de 40 mil colaboradores.</p>
          </div>
        </div>
      </div>

      <div>
        <div className="card-timeline bottom">
          <div className="timestamp"><span className='font-size-15'>2011</span></div>
          <div className="content content-top">
            <img src={bolsaValores} alt="Gráfico crescente com cifrão no final"></img>
            <p className='font-size-1'>Mais uma boa notícia: o DIA entra na <b>bolsa de valores</b> de Madrid.</p>
          </div>
        </div>
      </div>

      <div>
        <div className="card-timeline bottom">
          <div className="timestamp"><span className='font-size-15'>2019</span></div>
          <div className="content content-top">
            <img src={logoDia} alt="Logo do Supermercado Dia"></img>
            <p className='font-size-1'>Em Maio, a <b>LetterOne</b> assume controle majoritário do Grupo DIA – cerca 74% das ações da companhia – e dá início a uma fase de transformação global. Mikhail Fridman, co-fundador do grupo L1, atua como principal investidor.</p>
          </div>
        </div>
      </div>

      <div>
        <div className="card-timeline bottom">
          <div className="timestamp"><span className='font-size-15'>2022</span></div>
          <div className="content content-top">
            <img className="img-border" alt="fachada de um Supermercado DIA" style={{ maxWidth: 230 }} src={atualmente}/>
            <p className='font-size-1'>Há um Novo Dia - Definição do nosso propósito: "Estar cada dia mais perto para oferecer grande qualidade ao alcance de todos". Essa é a força motriz da nossa estratégia para construir um negócio responsável e sustentável, capaz de impactar positivamente as comunidades nas quais estamos inseridos.</p>
          </div>
        </div>
      </div>
      <div>

      <div className="card-timeline bottom">
        <div className="timestamp"><span className='font-size-15'>2023</span></div>
        <div className="content content-top">
          <img className="img-border" alt="fachada de um Supermercado DIA" style={{ maxWidth: 230 }} src={atualmente}/>
          <p className='font-size-1'>Renovação dos Valores Dia: Somos uma empresa que avança guiada pelo seu objetivo e orientada pelos seus valores. A aprendizagem é o nosso motor de crescimento e fomentamos a colaboração por meio de relações autênticas e de confiança; estamos empenhados nos resultados, e a simplicidade, somada à geração de valor, nos leva a conquistar todos os dias a nossa paixão: o cliente. Este é o nosso Novo Dia.</p>
        </div>
      </div>
      </div>

      <div>
        <div className="card-timeline bottom">
          <div className="timestamp"><span className='font-size-15'>2024</span></div>
          <div className="content content-top">
            <img className="img-border" alt="fachada de um Supermercado DIA" style={{ maxWidth: 230 }} src={atualmente}/>
            <p className='font-size-1'>Início de um processo de reestruturação no Dia Brasil com o objetivo de concentrar a nossa operação no Estado de São Paulo, onde operamos 244 lojas.</p>
            <br />
            <p className='font-size-1'>Fim do Vínculo com o Grupo Dia.</p>
          </div>
        </div>
      </div>


    </Swiper>
  )
}

const Banner = () => (
  <>
    <section className="container-fluid p-0 d-lg-block d-none">
      <div className="backgroud-banner-diaxpress w-100 d-flex align-items-center">
        <div className="container py-5">
          <div className="row h-100 mt-4">
            <div className="col-6 ml-5">
              <h1 className="ml-5 text-red font-size-18">A cada dia e em cada ocasião, mais perto de nossos clientes, com qualidade e melhor preço</h1>
              {/* <Link to="#swiperTimeline"><button className="btn btn-red-1">Conheça nossa história</button></Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="container-fluid p-0 d-lg-none d-block">
      <div className="backgroud-banner w-100"></div>
      <div className="container d-flex justify-content-center my-4">
        <div className="row justify-content-center m-0 p-0">
          <h2 className="text-center text-red mx-4 font-size-18">A cada dia e em cada ocasião, mais perto de nossos clientes, com qualidade e melhor preço</h2>
          <Link to="#swiperTimelineMobile"><button className="btn btn-red-1">Conheça nossa história</button></Link>
        </div>
      </div>
    </section>
  </>
)

const PrincipiosCards = () => (
  <>
    <section className="d-flex justify-content-center my-0 my-md-5 p-0">
      <div className="card-grid">
        <div className="card card-principios border-0"><img src={checkIcon} alt="icone de correto"/><p className='font-size-1'>O cliente está no centro de tudo o que fazemos.</p></div>
        <div className="card card-principios border-0"><img src={checkIcon} alt="icone de correto"/><p className='font-size-1'>Trabalhamos para melhorar constantemente em todas as áreas do negócio.</p></div>
        <div className="card card-principios border-0"><img src={checkIcon} alt="icone de correto"/><p className='font-size-1'>Buscamos atingir a excelência operacional em todas as partes da empresa.</p></div>
        <div className="card card-principios border-0"><img src={checkIcon} alt="icone de correto"/><p className='font-size-1'>Adotamos uma política de tolerância zero em relação à corrupção e comportamentos não éticos.</p></div>
        <div className="card card-principios border-0"><img src={checkIcon} alt="icone de correto"/><p className='font-size-1'>Reduzimos a complexidade e aplicamos o princípio: “Mantenha isso Simples”.</p></div>
        <div className="card card-principios border-0"><img src={checkIcon} alt="icone de correto"/><p className='font-size-1'>Controle de custos, eficiência nos processos, e tomadas de decisões rápidas são fatores-chave para o nosso sucesso.</p></div>
        <div className="card card-principios border-0"><img src={checkIcon} alt="icone de correto"/><p className='font-size-1'>Defendemos uma cultura de constante evolução, inovação e de soluções criativas. Acreditamos que erros fazem parte do processo de inovação.</p></div>
        <div className="card card-principios border-0"><img src={checkIcon} alt="icone de correto"/><p className='font-size-1'>Trabalhamos em cooperação com nossos empregados, franqueados, fornecedores e parceiros com base na justiça e no respeito mútuo.</p></div>
        <div className="card card-principios border-0"><img src={checkIcon} alt="icone de correto"/><p className='font-size-1'>A dedicação e o compromisso de todos os nossos empregados e franqueados são vitais para o sucesso a longo prazo da nossa empresa.</p></div>
      </div>
    </section>
    <div className="container mb-5 p-0">
      <div id="swiperPrincipios">
        <Swiper {...swiperParamsPrincipios}>
          <div className="bg-light card-principios border-0"><img src={checkIcon} alt="icone de correto"/><p className='font-size-1'>O cliente está no centro de tudo o que fazemos.</p></div>
          <div className="bg-light card-principios border-0"><img src={checkIcon} alt="icone de correto"/><p className='font-size-1'>Trabalhamos para melhorar constantemente em todas as áreas do negócio.</p></div>
          <div className="bg-light card-principios border-0"><img src={checkIcon} alt="icone de correto"/><p className='font-size-1'>Buscamos atingir a excelência operacional em todas as partes da empresa.</p></div>
          <div className="bg-light card-principios border-0"><img src={checkIcon} alt="icone de correto"/><p className='font-size-1'>Adotamos uma política de tolerância zero em relação à corrupção e comportamentos não éticos.</p></div>
          <div className="bg-light card-principios border-0"><img src={checkIcon} alt="icone de correto"/><p className='font-size-1'>Reduzimos a complexidade e aplicamos o princípio: “Mantenha isso Simples”.</p></div>
          <div className="bg-light card-principios border-0"><img src={checkIcon} alt="icone de correto"/><p className='font-size-1'>Controle de custos, eficiência nos processos, e tomadas de decisões rápidas são fatores-chave para o nosso sucesso.</p></div>
          <div className="bg-light card-principios border-0"><img src={checkIcon} alt="icone de correto"/><p className='font-size-1'>Defendemos uma cultura de constante evolução, inovação e de soluções criativas. Acreditamos que erros fazem parte do processo de inovação.</p></div>
          <div className="bg-light card-principios border-0"><img src={checkIcon} alt="icone de correto"/><p className='font-size-1'>Trabalhamos em cooperação com nossos empregados, franqueados, fornecedores e parceiros com base na justiça e no respeito mútuo.</p></div>
          <div className="bg-light card-principios border-0 mr-5"><img src={checkIcon} alt="icone de correto"/><p className='font-size-1'>A dedicação e o compromisso de todos os nossos empregados e franqueados são vitais para o sucesso a longo prazo da nossa empresa.</p></div>
        </Swiper>
      </div>
    </div>
  </>
)

const Pilares = () => (
  <>
    <section className="pilares-custom-margin">
      <div className="row mb-5">
        <div className="col-md-4 p-0">
          <div className=" d-flex flex-column pilares">
            <h2 id="two" tabIndex={0}>Pilares Corporativos</h2>
            <p className='font-size-1'>Em 2019, o DIA Brasil completou 18 anos de atuação no país e deu início a uma série de mudanças, que envolvem processos e <strong>novas formas de agir e pensar.</strong></p>
            <p className='font-size-1'>A companhia está comprometida, cada vez mais, em seguir princípios que valorizem atitudes como transparência, comportamento respeitoso e proativo, trabalho em equipe e redução da burocracia. Para colocá-los em DIA, a companhia valoriza quatro pilares de negócio: <strong>PREÇO, MARCA PRÓPRIA, FRESCOR e PROXIMIDADE.</strong></p>
          </div>
        </div>

        <div className="col-md-8 p-0">
          <div className="pilares-card w-100 bg-dia-gray">
            <div className="pilar-card align-self-end">
              <div className="pilar-card-header mb-3">
                <img src={preco} alt="desenho etiqueta de preço" className='lightMode'></img>
                <img src={precoDM} alt="desenho etiqueta de preço" className='darkMode'></img>
                <span className='font-size-18'>Preço</span>
              </div>
              <p className='font-size-1'>O DIA tem o preço mais acessível para todos os nossos clientes, que merecem os melhores produtos por um preço que caiba no bolso.</p>
            </div>
            <div className="pilar-card">
              <div className="pilar-card-header mb-3">
                <img src={marca} alt="desenho sacola de compras cheia de produtos" className="ml-2 lightMode"></img>
                <img src={marcaDM} alt="desenho sacola de compras cheia de produtos" className="ml-2 darkMode"></img>
                <span className='font-size-18'>Marca Própria</span>
              </div>
              <p className='font-size-1'>Produtos exclusivos de alta qualidade, desenvolvidos para garantir o que há de melhor na casa de milhões de consumidores.</p>
            </div>
            <div className="pilar-card">
              <div className="pilar-card-header mb-3">
                <img src={frescor} alt="desenho ramos de uma planta" className='lightMode'></img>
                <img src={frescorDM} alt="desenho ramos de uma planta" className='darkMode'></img>
                <span className='font-size-18'>Frescor</span>
              </div>
              <p className='font-size-1'>Foco em perecíveis: frutas, verduras e legumes, padaria e carnes. Produtos que aumentam o fluxo de clientes em loja.</p>
            </div>
            <div className="pilar-card align-self-end">
              <div className="pilar-card-header mb-3">
                <img src={proximidade} alt="desenho da fachada de uma loja" className='lightMode'></img>
                <img src={proximidadeDM} alt="desenho da fachada de uma loja" className='darkMode'></img>
                <span className='font-size-18'>Proximidade</span>
              </div>
              <p className='font-size-1'>Um DIA sempre perto dos consumidores, com lojas bem localizadas, atrativas e em lugares com alto fluxo de pessoas.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
)

const Institucional = () => {
  const breadC = {
    visible: true,
    color: 'black',
    items: [
      { path: '/', name: 'Home' },
      // { path: '/institucional/', name: 'Institucional' },
      { path: '/institucional/sobre-dia/', name: 'Sobre o Dia' }
    ]
  }

  return (
    <Layout breadCrumb={breadC} >
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <title>Sobre o Dia - Dia Supermercados</title>
        <meta name="description" content="Os nossos princípios são os direcionadores das nossas ações no DIA. Eles não são novos; já faziam parte dos nossos valores - Respeito, Iniciativa, Eficiência, Equipe e Cliente. Conheça a nossa história." />
        <meta name="keywords" content="dia supermercado, sobre o dia, pilares, historia do dia" />
        <meta name="author" content="DIA Supermercados" />
        <meta
          property="og:image"
          content='https://www.dia.com.br/card-dia.png'
        />
      </Helmet>
      <Banner/>
      <section className="container-md d-flex flex-column align-items-center principios p-0 mb-2">
        <h2 className="mb-4" id="one" tabIndex={0} >Princípios do DIA</h2>
        <p className="text-left text-sm-center px-4 p-md-0 font-size-1">Os nossos princípios são os direcionadores das nossas ações no DIA. Eles não são novos; já faziam parte dos nossos valores <strong>- Respeito, Iniciativa, Eficiência, Equipe e Cliente.</strong> Os nove princípios aprofundam nossos valores e teremos eles sempre presentes em nossa nova maneira de trabalhar:</p>
      </section>
      <PrincipiosCards/>
      <Pilares/>
      <section id="timeline" className="container-fluid p-0">
        <div>
          <div className="container d-flex justify-content-center">
            <h2 id="three" tabIndex={0}>Conheça nossa história</h2>
          </div>
        </div>
        <div className="timeline d-lg-block d-none">
          <div id="swiperTimeline">
            <Timeline/>
          </div>
        </div>
        <div className="timeline mobile-timeline d-lg-none d-block">
          <div id="swiperTimelineMobile">
            <TimelineMobile/>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default Institucional

export const query = graphql`{
  firstshop: file(relativePath: {eq: "institucional/1979.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 230, height: 150, layout: CONSTRAINED)
    }
  }
  diabrasil: file(relativePath: {eq: "institucional/2001.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 230, height: 150, layout: CONSTRAINED)
    }
  }
  atualmente: file(relativePath: {eq: "institucional/2020.jpeg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 230, height: 130, layout: CONSTRAINED)
    }
  }
  logo: file(relativePath: {eq: "logo-institucional.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
